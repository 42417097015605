import { Country } from '~/types/enums/Country';
import type { Car } from '~/types/interfaces/car/Car';

export const getCarPrice = (car: Car) => {
    const config = useRuntimeConfig();

    if (config.public.siteMode === 'B2B') {
        if (car.stickerpricedata && car.stickerpricedata.price) {
            return {
                fromPrice: getNettoPrice(car.stickerpricedata.price),
                crossFromPrice: getNettoPrice(car.stickerpricedata.default_price),
            };
        } else {
            // No cross price! Only when stickerprice is available
            return {
                fromPrice: getNettoPrice(car.dynamic_from_price),
            };
        }
    } else {
        if (car.stickerpricedata && car.stickerpricedata) {
            return {
                fromPrice: car.stickerpricedata.price,
                crossFromPrice: car.stickerpricedata.default_price,
            };
        } else {
            // No cross price! Only when stickerprice is available
            return {
                fromPrice: car.dynamic_from_price,
            };
        }
    }

    return { fromPrice: car.dynamic_from_price, crossFromPrice: car.dynamic_cross_from_price };
};

function getNettoPrice(price: number) {
    const currentCountry = useCurrentCountry();

    switch (currentCountry.value) {
        case Country.AT:
            return Math.round(price / 1.2);
        case Country.DE:
            return Math.round(price / 1.19);
        case Country.CH:
            return Math.round(price / 1.077);
        case Country.FR:
            return Math.round(price / 1.2);
        default:
            return price;
    }
}
