/**
 * This function builds the url for the configurator based on the car slug and the environment
 * @param car_slug slug of the car
 */
export const buildConfiguratorUrl = (car_slug: string) => {
    const config = useRuntimeConfig();
    const country = useCurrentCountry();

    return `${
        config.public.env == 'development'
            ? 'https://konfigurator-staging.insta-drive.com'
            : 'https://konfigurator.insta-drive.com'
    }/configure/${car_slug}/?cc=${country.value}${config.public.siteMode === 'B2B' ? '&business=1' : ''}`;
};
